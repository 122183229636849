var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kiwi-controlinput kiwi-theme-bg",class:{
        'kiwi-controlinput-selfuser--open': _vm.selfuser_open,
        'kiwi-controlinput--focus': _vm.has_focus,
    }},[_c('div',{staticClass:"kiwi-controlinput-selfuser"},[_c('transition',{attrs:{"name":"kiwi-selfuser-trans"}},[(_vm.networkState==='connected'
                    && _vm.selfuser_open === true)?_c('self-user',{attrs:{"network":_vm.buffer.getNetwork()},on:{"close":function($event){_vm.selfuser_open=false}}}):_vm._e()],1)],1),_c('div',{staticClass:"kiwi-controlinput-inner"},[(_vm.buffer.getNetwork() && _vm.buffer.getNetwork().state === 'connected')?_c('away-status-indicator',{attrs:{"network":_vm.buffer.getNetwork(),"user":_vm.buffer.getNetwork().currentUser()}}):_vm._e(),(_vm.currentNick)?_c('div',{staticClass:"kiwi-controlinput-user",on:{"click":_vm.toggleSelfUser}},[_c('span',{staticClass:"kiwi-controlinput-user-nick"},[_vm._v(_vm._s(_vm.currentNick))]),_c('i',{staticClass:"fa",class:[_vm.selfuser_open ? 'fa-caret-down' : 'fa-caret-up'],attrs:{"aria-hidden":"true"}})]):_vm._e(),(_vm.shouldShowInputButtons)?_c('div',{ref:"plugins",staticClass:"kiwi-controlinput-tools"},[_c('transition',{attrs:{"name":"kiwi-plugin-ui-trans"}},[(_vm.showPlugins)?_c('div',{staticClass:"kiwi-controlinput-tools-container"},[(_vm.shouldShowColorPicker)?_c('a',{staticClass:"kiwi-controlinput-tool",on:{"click":function($event){$event.preventDefault();return _vm.onToolClickTextStyle($event)}}},[_c('i',{staticClass:"fa fa-font",attrs:{"aria-hidden":"true"}})]):_vm._e(),(_vm.shouldShowEmojiPicker)?_c('a',{staticClass:"kiwi-controlinput-tool",on:{"click":function($event){$event.preventDefault();return _vm.onToolClickEmoji($event)}}},[_c('i',{staticClass:"fa fa-smile-o",attrs:{"aria-hidden":"true"}})]):_vm._e(),_vm._l((_vm.pluginUiElements),function(plugin){return _c('div',{directives:[{name:"rawElement",rawName:"v-rawElement",value:({
                            el: plugin.el,
                            props: {
                                controlinput: _vm.self,
                            }
                        }),expression:"{\n                            el: plugin.el,\n                            props: {\n                                controlinput: self,\n                            }\n                        }"}],key:plugin.id,staticClass:"kiwi-controlinput-tool"})})],2):_vm._e()])],1):_vm._e(),_c('form',{staticClass:"kiwi-controlinput-form",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[(_vm.autocomplete_open)?_c('auto-complete',{ref:"autocomplete",attrs:{"items":_vm.autocomplete_items,"filter":_vm.autocomplete_filter,"buffer":_vm.buffer},on:{"temp":_vm.onAutocompleteTemp,"selected":_vm.onAutocompleteSelected,"cancel":_vm.onAutocompleteCancel}}):_vm._e(),(_vm.buffer.setting('share_typing'))?_c('typing-users-list',{attrs:{"buffer":_vm.buffer}}):_vm._e(),_c('div',{staticClass:"kiwi-controlinput-input-wrap"},[_c('irc-input',{ref:"input",staticClass:"kiwi-controlinput-input",attrs:{"placeholder":_vm.$t('input_placeholder'),"wrap":"off"},on:{"input":_vm.inputUpdate,"keydown":function($event){return _vm.inputKeyDown($event)},"keyup":function($event){return _vm.inputKeyUp($event)},"focus":function($event){_vm.has_focus = true},"blur":function($event){_vm.has_focus = false}}})],1),(_vm.shouldShowSendButton)?_c('button',{staticClass:"kiwi-controlinput-send fa fa-paper-plane",attrs:{"type":"submit"}}):_vm._e()],1)],1),_c('div',{staticClass:"kiwi-controlinput-active-tool"},[_c(_vm.active_tool,_vm._b({tag:"component"},'component',_vm.active_tool_props,false))],1)])}
var staticRenderFns = []

export { render, staticRenderFns }